
import RenderInstructionService from "@/services/RenderInstructionService"
import ProcessingService from "../../../../services/ProcessingService"
import { ref, reactive } from "vue"
import CustomProcessingsForm from "./CustomProcessingsForm.vue"

export default {
  components: { CustomProcessingsForm },
  setup() {
    const service = new RenderInstructionService("customprocessings")
    const additionalFields = reactive({
      alias: "",
      processings: "",
    })

    const columns = reactive([{ field: "alias", header: "Alias", width: "6rem" }])

    const collection = ref()

    function closeDialogs() {
      collection.value.addDialog = false
      collection.value.editDialog = false
      collection.value.duplicateDialog = false
      collection.value.reload()
    }

    return { service, additionalFields, columns, collection, closeDialogs }
  },
}
