
import { reactive, ref } from "vue"
import RenderInstructionService from "../../../../services/RenderInstructionService"
import CustomProcessingsValue from "./CustomProcessingsValue.vue"

export default {
  components: { CustomProcessingsValue },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    duplicate: {
      type: Boolean,
      default: false,
    },
    selectedRule: {
      type: Object,
      default: null,
    },
  },
  emits: ["closedialogs"],
  setup(props: any, { emit }: any) {
    const service = new RenderInstructionService("customprocessings")
    const additionalFields = reactive({ alias: "", processings: {} })
    const form = ref()

    function onCreate() {
      form.value.addRule(additionalFields)
    }

    function onEdit() {
      form.value.editRule(additionalFields)
    }

    function closeDialogs() {
      emit("closedialogs")
    }

    function onRetrieve(rule: any) {
      additionalFields.alias = rule.alias
      service.getProcessings(rule.id).then((result) => {
        additionalFields.processings = JSON.stringify(result)
      })
    }

    return {
      service,
      form,
      additionalFields,
      onCreate,
      onEdit,
      onRetrieve,
      closeDialogs,
    }
  },
}
