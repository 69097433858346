
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"

export default {
  props: {
    additionalFields: { alias: String, processings: String },
  },
  setup(props: any) {
    const form = {
      alias: { aliasRequired: helpers.withMessage("The alias is required", required) },
      processings: {
        processingsRequired: helpers.withMessage("The processings are required", required),
      },
    }

    const v$ = useVuelidate(form, props.additionalFields)

    return {
      v$,
    }
  },
}
